import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import styled from "styled-components"
import pdfLogo from '../assets/pdficon_small.gif'
import { TEST_ONLY, ROUTES, FLASHCARD_ONLY, FULL_ACCESS, TESTS_AND_FLASHCARDS } from '../constants'
import { StoreContext } from '../contexts/storeContext'
import { StoreConextInterface } from '../types'
import { AppTitle, ContentBox } from './common'

const StyledExtraInfo = styled.div`
  img{
    height: 18px;
  }

  >div{
    margin-bottom: 1rem;
  }
`

interface extraInfoFileInterface {
  name: string
  fileName: string
}

interface extraInfoSectionsInterface {
  name: string
  content: extraInfoFileInterface[]
}

const extraInfoSections: extraInfoSectionsInterface[] = [
  {
    name: 'Important Handouts',
    content: [
      {
        name: 'CF 7501 Guideline - Box by Box on the 7501',
        fileName: '7501Guide.pdf'
      },
      {
        name: 'Numbered scratch paper for practice and test day',
        fileName: 'CBLEexamnotes.pdf'
      },
      {
        name: 'A few suggestions for test day',
        fileName: 'PreparingfortheTest.pdf'
      },
      {
        name: 'HTS Desctiptions by Headings - a great list of the HTS Headings',
        fileName: 'HTSHeadings.pdf'
      },
      {
        name: 'Handouts and Documents for Live Seminar',
        fileName: 'SeminarDocs.pdf'
      },
    ]
    },
  {
    name: 'Miscellaneous Notes and Downloads',
    content: [
      {
        name: 'Preparing for the Test',
        fileName: 'PreparingfortheTest.pdf'
      },
    ]
  }
]

export const ExtraInfo = () => {

  const { access } = useContext<StoreConextInterface>(StoreContext)

  if (access === TEST_ONLY || access === TESTS_AND_FLASHCARDS) return <Navigate to={ROUTES.TESTS} />
  else if (access === FLASHCARD_ONLY) return <Navigate to={ROUTES.FLASHCARDS} />
  else if (access === FULL_ACCESS) {
    return (
      <div>
        <AppTitle />
        <ContentBox>
          <StyledExtraInfo>
            <div>All downloads on this page are in Adobe PDF Adobe <img alt={''} src={pdfLogo} /> format.</div>
            <div><b>I think it is easy to have too much information, so do not feel
            that you absolutley have to have the information below. Occaisionally I
            come accross something that I think is worth sharing with students.
            Over the years this list has become smaller as we try to make our exam
            guide as complete as possible.</b></div>

            {extraInfoSections.map((section, i) => (
              <div key={`extraInfoSection${i}`}>
                <h3>{section.name}</h3>
                <ul>
                  {section.content.map((file, j) => (
                    <li key={`extraInfoFile${j}`}>
                      <a
                        rel="noopener noreferrer"
                        download={file.fileName}
                        target="_blank"
                        href={require(`../assets/extra/${file.fileName}`)}
                      >{file.name}</a>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </StyledExtraInfo>
        </ContentBox>
      </div>
    )
  } else {
    return <></>
  }
}
