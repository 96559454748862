import React, { useRef } from "react";
import { useReactToPrint } from 'react-to-print';
import styled from "styled-components";
import { ShuffledAnswers, Test } from "../types";
import { TakeTestQuestion } from "./take-test-question";

interface StyledTakeTestFullProps {
    showPrint: boolean;
}

const StyledTakeTestFull = styled.div<StyledTakeTestFullProps>`
  display: ${(props: StyledTakeTestFullProps) => (props.showPrint ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  max-width: none;
  padding: 1rem;
  background-color: #FFF;
  overflow-y: auto;
  z-index: 1000;

  @media print {
    display: block !important;
    visibility: visible !important;
    position: static;
    overflow: visible;
    height: auto;
    width: 100% !important;
    max-width: none !important;
    padding: 0 !important;
    margin: 0 !important;
  }
`;

const ActionButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 1000;

  @media print {
    display: none;
  }
`;

const ActionButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: #FFF;
  border: 1px solid #016BAC;
  color: #016BAC;
  cursor: pointer;
  margin-bottom: 0.5rem;

  &:hover {
    background-color: #016BAC;
    color: #FFF;
  }
`;

const PrintableContent = styled.div`
  @media print {
    width: 100% !important;
    max-width: none !important;
    padding: 0 !important;
    margin: 0 !important;
  }
`;

const QuestionContainer = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);

  @media print {
    break-inside: avoid;
    page-break-inside: avoid;
    border: none;
    box-shadow: none;
  }
`;

// Add this new styled component
const QuestionWrapper = styled.div`
  @media print {
    page-break-inside: avoid;
    break-inside: avoid;
  }
`;

interface DisplayFullTakeTestProps {
    test: Test;
    shuffledAnswers: ShuffledAnswers;
    onHide: () => void;
    showPrint: boolean;
}

export const DisplayFullTakeTest = ({ test, shuffledAnswers, onHide, showPrint }: DisplayFullTakeTestProps) => {
    const contentRef = useRef(null);

    const handlePrint = useReactToPrint({
        content: () => contentRef.current,
        documentTitle: `Test_${test.id}_Results`,
        onAfterPrint: () => console.log('Printed successfully!'),
    });

    return (
        <StyledTakeTestFull showPrint={showPrint}>
            <ActionButtonContainer>
                <ActionButton onClick={onHide}>Hide Printable Version</ActionButton>
                <ActionButton onClick={handlePrint}>Save as PDF</ActionButton>
            </ActionButtonContainer>
            <PrintableContent ref={contentRef}>
                {test.questions.map((question, i) => (
                    <QuestionContainer key={i}>
                        <QuestionWrapper>
                            <TakeTestQuestion
                                test={test}
                                currentQuestion={question}
                                updateIndex={() => { }}
                                shuffledAnswers={shuffledAnswers}
                                answerQuestion={() => { }}
                                currentUserTestAnswerObject={{
                                    seen: false,
                                    name: '',
                                    fromTest: '',
                                    testId: 0,
                                    questionId: 0,
                                    answer: '',
                                    isCorrect: false,
                                    flagged: false,
                                    highlighted: false,
                                }}
                                index={i}
                                showAnswer={true}
                                maxQuestions={test.questions.length}
                                isFullPage={true}
                            />
                        </QuestionWrapper>
                    </QuestionContainer>
                ))}
            </PrintableContent>
        </StyledTakeTestFull>
    );
};