import { CategoryVideos } from "./types";

export const VIDEOS: CategoryVideos[] =
  [
    {
      title: 'Chapter Lectures',
      videos: [
        {
          link: 'https://youtu.be/SwlQtqx1Kxg',
          id: 'intro',
          title: 'Introduction',
        },
        {
          link: 'https://youtu.be/dNZPAoLttaw',
          id: 'basicSetup',
          title: '1. CBP Basic Set Up',
        },
        {
          link: 'https://youtu.be/c2erxUP0S8k',
          id: 'basicEntry',
          title: '2. Basic Entry and Entry Summary',
        },
        {
          link: 'https://youtu.be/T2wBW2xwnkY',
          id: 'basicEntryReq',
          title: '3. Basic Entry Requirements part I',
        },
        {
          link: 'https://youtu.be/1XeLR_eix3k',
          id: 'basicEntryReq2',
          title: '3. Basic Entry Requirements part II',
        },
        {
          link: 'https://youtu.be/VkKteO4HTrQ',
          id: 'specialEntryProc',
          title: '4. Special Entry Procedures',
        },
        {
          link: 'https://youtu.be/G1Y-zYedCzk',
          id: 'bonds',
          title: '5. Bonds',
        },
        {
          link: 'https://youtu.be/kEDKzGvikqQ',
          id: 'liquidations',
          title: '6. Liquidations and Currency',
        },
        {
          link: 'https://youtu.be/LJgT6SHqBQA',
          id: 'dutyRefund',
          title: '7. Duty Refund and GO',
        },
        {
          link: 'https://youtu.be/ia44X2YhEw0',
          id: 'cfr24',
          title: '8. CFR 24',
        },
        {
          link: 'https://youtu.be/GRBKKTVetKo',
          id: 'valuationApp',
          title: '9. Valuation & Appraisement (Part One)',
        },
        {
          link: 'https://youtu.be/UqBFe_0FyKM',
          id: 'valTransValue',
          title: 'Valuation - Transaction Value (Part two)',
        },
        {
          link: 'https://youtu.be/ZNF4QSvkX6I',
          id: 'valOtherMethods',
          title: 'Valuation - other methods (Part three)',
        },
        {
          link: 'https://youtu.be/3mCVndEGiUU',
          id: 'mathValuation',
          title: 'Sample Math Valuation Lecture - Nothing but math! - Good Stuff',
          pdfs: [{ file: 'Samplemath.pdf', title: 'Get Sample Questions' }]
        },
        {
          link: 'https://youtu.be/XHa5enIhk4E',
          id: 'customsBrokers',
          title: '10. Customs Brokers part 1 - 13 min)',
        },
         {
          link: 'https://youtu.be/kAIBYLKiE_c',
          id: 'customsBrokers',
          title: '10. Customs Brokers part 2 - 20 min)',
        },
        {
          link: 'https://youtu.be/7rwfXcF_iSY',
          id: 'transMovements',
          title: '11. Transportation Movements - ITs',
        },
        {
          link: 'https://youtu.be/ssueefjjgmc',
          id: 'prohRestGoods',
          title: '12. Prohibited and Restricted Goods',
        },
        {
          link: 'https://youtu.be/KJZ4LW93_BU',
          id: 'quota',
          title: '13. Quota',
        },
        {
          link: 'https://youtu.be/kM2V30y_OaI',
          id: 'tradeTradeCopy',
          title: '14. Trademark, Tradename, and Copyright',
        },
        {
          link: 'https://youtu.be/fOE4g5NL6qY',
          id: 'countryOrigin',
          title: '15. Country of Origin',
        },
        {
          link: 'https://youtu.be/vLfFux-KehA',
          id: 'marking',
          title: '16. Marking',
        },
        {
          link: 'https://youtu.be/WW8KsNL6IlE',
          id: 'warehouseEntriesAdmin',
          title: '17. Warehouse Entries and Warehouse Administration',
        },
        {
        link: 'https://youtu.be/w_U1C3rgfZ4',
          id: 'specialEntryProc',
          title: '18. AD CV and Part 200 to end (new 3.26.24)',
        },
        {
          link: 'https://youtu.be/X3wqVuQ7-pE',
          id: 'nafta',
          title: '19. USMCA',
        },
        {
          link: 'https://youtu.be/iGJkqZQTE80',
          id: 'bindingRulings',
          title: '20. Binding Rulings',
        },
        {
          link: 'https://youtu.be/5bzlURZ1JWA',
          id: 'protest',
          title: '21. Protest',
        },
        {
          link: 'https://youtu.be/i-hKAFnFmxk',
          id: 'finesPenal',
          title: '22a. Fines and Penalties',
        },
        {
          link: 'https://youtu.be/wLd-UwhjBMQ',
          id: 'petRelief',
          title: '22b. Petitions for Relief',
        },
        {
          link: 'https://youtu.be/-HyT1Firr80',
          id: 'hello62',
          title: '23. Foreign Trade Zone',
        },
        {
          link: 'https://youtu.be/4GKvw6W1yHA',
          id: 'drawback',
          title: '24. Drawback',
        },
        {
          link: 'https://youtu.be/II16hvSP-Zo',
          id: 'recordKeep',
          title: '25. Record Keeping',
        },
        {
          link: 'https://youtu.be/11FCbuDGVYE',
          id: 'carnets',
          title: '26. Carnets',
        },
        {  
          link: 'https://youtu.be/Mj2uGhO6g-k',
          id: 'htsIntro',
          title: '27-29 video lecture are inlcuded below in classification school and trade programs',
        },  
        { 
          link: 'https://youtu.be/di3vOfnz6LI',
          id: 'textiles',
          title: '30. Textiles'
        }
      ]
    },
    {
      title: 'ACE DOCS and Directives',
      videos: [
       
         {
          link: 'https://youtu.be/aF-JMVbT0iY',
          id: 'cf7501',
          title: 'ACE Entry Summary'
        },
        {
          link: 'https://youtu.be/zTmHkWPVN74',
          id: 'catair',
          title: 'Ace Business Rules and Process Document'
        },
        {
          link: 'https://youtu.be/cnBxx-T5Mb4',
          id: 'PSC',
          title: 'PSC, Recon, and Post Entry Duty Refund',
          pdfs: [{ file: 'PSCandRecon.pdf', title: 'Get PSC and Recon cheat sheet' }]
        },
        {
          link: 'https://youtu.be/c2bkXvPfVbw',
          id: 'dirRightMake',
          title: 'Directive - Right to Make Entry'
        },
        {
          link: 'https://youtu.be/ApN7XkcM6pM',
          id: 'midCode',
          title: 'MID Code'
        },
        {
          link: '',
          id: 'sampleMultiFact',
          title: 'Reserved'
        }
      ]
    },
    {
      title: 'Classification School, 6 Steps',
      videos: [
        {
          link: 'https://youtu.be/HTkfoDgbniI',
          id: 'htsIntro2',
          title: '1. (ch 27) HTS Introduction - What\'s There! What\'s Where!'
        },
        {
          link: 'https://youtu.be/CHLB1ipTkys',
          id: 'genRules',
          title: '2. General Rules of Interpretation'
        },
        {
          link: 'https://youtu.be/yn6IjcM1OyU',
          id: 'basicTariff',
          title: '3. Basic Tariff Page Explanation (watch this!!!)'
        },
        {
          link: 'https://youtu.be/PhRz6jOnEXs',
          id: 'howClassify',
          title: '4. How to Classify part 1',
          pdfs: [{ file: 'Sampleclassification.pdf', title: 'Get Sample Classification Questions' }]
        },
        {
          link: 'https://youtu.be/Giu0zAxufew',
          id: 'howClassify2',
          title: '4. How to Classify part 2',
        },
        {
          link: 'https://youtu.be/3xJthqr4GUw',
          id: 'returningGoods',
          title: '5. (ch 29) HTS Ch. 98 - Returning Goods'
        },
        {
          link: 'https://youtu.be/u-jKyc-yyvg',
          id: 'tempImports',
          title: '6. (ch 29) Temporary Imports Under Bond'
        },
        {
          link: 'https://youtu.be/fa-7Tk4bpG4',
          id: 'ChinaTariffs',
          title: 'Explaining China Tariffs', pdfs: [{ file: 'Chinatariff.pdf', title: 'Get China Tariff Cheat Sheet' }]
        }
      ]
    },
    {
      title: 'Trade Programs',
      videos: [
        {
          link: 'https://youtu.be/pX7EVUDz7d0',
          id: 'tradePrograms',
          title: 'Ch 28 Trade Programs - a short primer'
        },
        {
          link: 'https://youtu.be/Bepdnyx2Lic',
          id: 'theGsp',
          title: 'The GSP'
        },
        {
          link: 'https://youtu.be/i4mfQs_yVZo',
          id: 'nafta2',
          title: 'USMCA'
        },
        {
          link: 'https://youtu.be/tbtzfTdVRS0',
          id: 'textTrade',
          title: 'Textiles and Trade Programs'
        },
        {
          link: 'https://youtu.be/NbGdi10Dy5E',
          id: 'ftaCompChart',
          title: 'FTA Comparison Chart - Super Important Tools'
        }
      ]
    }
  ]
